import messages from '../locales';
import dayjs from 'dayjs';
import { QuoteVersion } from '@/api/sales/request/sales.request';
import { QuoteProcedureItemDto } from '@/models/quote-procedure-item-dto';
import { QuoteAccessoryItemDto } from '@/models/quote-accessory-item-dto';
import { FinancedPaymentDto } from '@/models';

export const getImage = (value) => {
    return `${import.meta.env.VITE_MEDIA_URL}${value}`;
};

export const getBrowserLocale = (options = {}) => {
    const defaultOptions = { countryCodeOnly: true };
    const opt = { ...defaultOptions, ...options };
    const navigatorLocale =
        navigator.languages != undefined ? navigator.languages[0] : navigator.language;
    if (!navigatorLocale) return undefined;
    const trimmedLocale = opt.countryCodeOnly
        ? navigatorLocale.trim().split(/-|_/)[0]
        : navigatorLocale.trim();
    return trimmedLocale;
};

export const supportedLocalesInclude = (locale) => {
    return Object.keys(messages).includes(locale);
};

export const printHtml = () => {
    return window.print();
};

export const unMaskPhone = (phone: string): string => {
    if (phone) return phone.replace(/[-()\s+]/g, '');
    return phone;
};

export const parseTo12hTime = (dateTime: string) =>
    dayjs(dateTime, 'YYYY-MM-DDTHH:mm:ss').format('hh:mm A');

export const isMultiQuote = (version: number) => {
    return version == QuoteVersion.MULTIPLE;
};
export const isVersion = (version: number, versions: Array<number>) => {
    return versions.some((e) => e === version);
};
export const isFinancedQuote = (version: number) => {
    return version == QuoteVersion.FINANCED;
};
export const isFullPaid = (item: QuoteProcedureItemDto | QuoteAccessoryItemDto) => {
    return item.paid >= Number((item.price * item.quantity).toFixed(2));
};
export const isFullPaidFee = (item: FinancedPaymentDto) => {
    return item.paid >= item.maxAmount;
};
export const somePayment = (paid: number) => {
    return paid > 0;
};

export function formatDatePicker(dateString) {
    const date = dayjs(dateString);
    if (!dateString) return '';
    return date.format('YYYY-MM-DD');
}
export class ErrorResult {
    private message: string;
    private data: any;
    private status: number;
    constructor(message, status, data = {}) {
        this.message = message;
        this.data = data;
        this.status = status;
    }
    toString() {
        return `${this.message}`;
    }
    isSystemException() {
        return this.status === 500;
    }
    isCanceledError() {
        return this.data?.code === 'ERR_CANCELED';
    }
}
